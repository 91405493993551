import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ContentfulRichText from '../components/contentfulRichText';
import { BlogPagesQueryQuery } from '../../types/graphql-types'; // eslint-disable-line import/no-unresolved
import Container from '../components/Container';

const WrapLink = styled(Link)`
  text-decoration: none;
`;

const PostImage = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
`;

type Props = {
  data: BlogPagesQueryQuery;
};

const BlogPosts: React.FC<Props> = ({ data }: Props) => {
  const documents = data?.allContentfulBlogPost.edges.map(edge => edge.node);
  return (
    <Layout>
      <SEO title="Blog" />
      <div style={{ marginTop: '6rem' }} />

      <Container variant="wide">
        <Typography variant="h1">Blog</Typography>
        {documents?.map(node => {
          return (
            <div key={node.id}>
              <h2 key={`${node.id}-title`}>{node.title}</h2>
              {node.featuredImage ? (
                <Grid item key={node.slug} xs={12} md={7}>
                  <WrapLink to={`/portfolio/${node.slug}`}>
                    <PostImage
                      src={node.featuredImage.fluid.src}
                      srcSet={node.featuredImage.fluid.srcSet}
                    />
                  </WrapLink>
                </Grid>
              ) : null}
              <ContentfulRichText
                document={node.body?.json}
                key={`${node.id}-content`}
              />
            </div>
          );
        })}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query BlogPagesQuery {
    allContentfulBlogPost(limit: 10) {
      edges {
        node {
          id
          slug
          title
          updatedAt
          featuredImage {
            fluid(maxWidth: 520) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          body {
            json
          }
        }
      }
    }
  }
`;

export default BlogPosts;
